import {
  debounce, ElementController, getData,
  insertBefore,
  isTruthy,
  modifierKeyWatcher,
  selectAll,
  selectSingle,
  selectChild,
  setData, TOGGLE_EVENT
} from 'js-common';
import { isButtonActivationKey } from './utils';





const SEARCH_ITEM_SELECTOR = '.header-search-item';
const GLOBAL_NOTICE_SELECTOR = '.notice';
const DROPDOWN_TITLE_ITEM_CLASS = 'title-item';
const DROPDOWN_TRIGGER_ATTR_DATA_ATTR = 'dropdown-trigger-attr';
const TOP_LEVEL_PARENT_DATA_ATTR = 'top-level-parent';
const MOUSE_STATUS_DATA_ATTR = 'mouse';

/**
 * Main and secondary navigation dropdown menus.
 *
 * The main purpose with this is to make the dropdown menus work for all people,
 * not just those with mice or other precision pointers that have a hover state.
 *
 * - For touch screens, the dropdown parent links behave like buttons that open
 *   the dropdown on click instead of going to the link URL.
 * - For keyboard users, the open state is set on the dropdown parent li on
 *   focus and kept when focus is inside the dropdown.
 *
 * In both cases, the aria-expanded attribute is set on the parent li and the
 * CSS must treat `a[aria-expanded='true']` just like `li:hover`.
 *
 * On smaller screens the dropdowns are toggled on click without any auto
 * expansion for keyboard focus.
 *
 * @extends {ElementController}
 */
export default class Menu extends ElementController {
  static id = 'Menu';

  /**
   * DOM loaded, initialize elements and bind events.
   */
  init() {
    this.mainNav = this.selectSingle('main-nav');
    this.dropdownNavs = this.selectAll('dropdown-nav');
    this.dropdownCount = 0;
    this.globalNotice = selectSingle(GLOBAL_NOTICE_SELECTOR);

    this.header = this.selectSingle('header');
    this.menuToggle = this.selectSingle('menu-toggle');
    this.menuToggleStyle = window.getComputedStyle(this.menuToggle);
    this.menuList = selectChild(this.mainNav, 'ul');
    this.lastMainNavLink = selectAll(this.mainNav, 'a').pop();

    this.on(this.mainNav, 'click', this.handleMainNavClick);
    this.on(this.mainNav, 'keydown', this.handleMainNavKeydown);
    this.on(this.lastMainNavLink, 'blur', this.handleMainNavLastBlur);
    this.on(this.menuToggle, 'blur', this.handleMenuToggleBlur);

    this.checkMenuToggleState();
    this.setToggleMenuHeight();
    this.on(window, 'resize', debounce(this.handleResize, 200));

    // Get elements.
    this.topLevelParentLinks = this.dropdownNavs.reduce(
      (items, menu) =>
        items.concat(selectAll(menu, '[data-level="1"] > .has-children > a')),
      [],
    );

    // Make adjustments for functionality and accessibility that are only
    // relevant with JS available.
    this.dropdownify();

    // Search form toggle.
    const searchItem = selectSingle(this.root, SEARCH_ITEM_SELECTOR);
    if (searchItem) {
      this.searchInput = selectSingle(searchItem, 'input[type="search"]');
      this.searchToggle = selectChild(
        searchItem,
        '[data-controller="Toggle"]',
        );
      // Menu is broken when site is Google-translated which results in Sentry
      // errors, guard to shut it up.
      if (this.searchToggle) {
        this.on(this.searchToggle, TOGGLE_EVENT.toggle, this.handleSearchToggle);
      }
      if (this.searchInput) {
        this.on(this.searchInput, 'keydown', this.handleSearchInputKeydown);
      }
    }

    // Bind events for the dropdown parent links.
    this.on(
      this.dropdownNavs,
      'click',
      '[role="button"]',
      this.handleButtonizedLinkClick,
    );
    this.on(
      this.dropdownNavs,
      'keydown',
      '[role="button"]',
      this.handleButtonizedLinkKeydown,
    );

    // Handle outside clicks
    this.on(document.body, 'click', this.handleBodyClick);

    // Ugly hack for Chrome transition bug, see CSS.
    // https://stackoverflow.com/questions/14389566/stop-css3-transition-from-firing-on-page-load
    setTimeout(() => {
      document.documentElement.classList.remove('no-menu-ready');
    }, 200);
  }

  /**
   * Check if the menu is currently in its toggled state.
   */
  checkMenuToggleState = () => {
    this.isToggledMenu = this.menuToggleStyle.display !== 'none';
  };

  /**
   * Set the menu height if a global notice is active.
   *
   * The menu only uses viewport units by default since it's normally at the
   * top of the screen. With a notice active, the entire page is pushed down
   * a number of pixels that depends on the notice content, screen size, font
   * sizes etc.
   */
  setToggleMenuHeight = () => {
    if (this.globalNotice) {
      if (this.isToggledMenu) {
        const offset = this.globalNotice.offsetHeight;
        this.mainNav.style.height = `calc(100vh - ${offset}px)`;
      } else {
        this.mainNav.removeAttribute('style');
      }
    }
  };

  /**
   * Set or remove attributes on dropdown links depending on menu toggle state.
   *
   * When the menu is in its toggled state the whole tree is expanded without
   * any sub menu toggling, so the links should not be announced as buttons.
   *
   * @param {Element} a - Dropdown link.
   */
  setDropdownLinkAttr(a) {
    const attrs = getData(a, DROPDOWN_TRIGGER_ATTR_DATA_ATTR, JSON.parse);
    if (attrs) {
      if (this.isToggledMenu) {
        Object.keys(attrs).forEach((attr) => {
          a.removeAttribute(attr);
        });
      } else {
        Object.entries(attrs).forEach((attr) => {
          a.setAttribute(attr[0], attr[1]);
        });
      }
    }
  }

  /**
   * Make adjustments to menu items with children.
   *
   * - Adds the top level link as the first item in the dropdown.
   * - Makes links behave like buttons.
   * - Binds events to make keyboard focus work.
   */
  dropdownify() {
    this.topLevelParentLinks.forEach((a) => {
      /* eslint-disable no-param-reassign */
      this.dropdownCount += 1;
      const dropdownId = `nav-dropdown-${this.dropdownCount}`;

      // Add the main link as the first sub menu item.
      const subMenu = selectChild(a.parentNode, '.sub-menu');
      // Do a shallow clone and set the text to get rid of any nested elements
      const linkClone = a.cloneNode();
      linkClone.textContent = a.textContent;
      linkClone.setAttribute('aria-label', a.textContent.trim());

      const titleItem = document.createElement('li');
      titleItem.classList.add(DROPDOWN_TITLE_ITEM_CLASS);
      if (linkClone.href === window.location.href) {
        titleItem.classList.add('current-item');
      }
      titleItem.append(linkClone);
      insertBefore(subMenu.firstChild, titleItem);

      // Check if the sub menu is only one level deep
      const subMenuLi = selectAll(subMenu, 'li');
      if (!subMenuLi.some((li) => li.classList.contains('has-children'))) {
        subMenu.classList.add('sub-menu--single-level');
      }

      // Make the link behave like a button and bind it to the dropdown
      setData(
        a,
        DROPDOWN_TRIGGER_ATTR_DATA_ATTR,
        JSON.stringify({
          'role': 'button',
          'aria-controls': dropdownId,
          'aria-expanded': 'false',
        }),
      );
      this.setDropdownLinkAttr(a);
      subMenu.id = dropdownId;

      // Close when leaving the last link
      a.parentNode.setAttribute(`data-${TOP_LEVEL_PARENT_DATA_ATTR}`, '');
      const lastSubMenuLink = selectAll(subMenu, 'a').pop();
      this.on(lastSubMenuLink, 'blur', this.handleLastDropdownLinkBlur);

      // Special 'click white listing' for mouse users
      this.on(a.parentNode, 'mouseenter', this.handleDropdownParentMouseEnter);
      this.on(a.parentNode, 'mouseleave', this.handleDropdownParentMouseLeave);
    });
  }

  /**
   * Get a dropdown anchor element from the specified target.
   *
   * @param {Element} target - Target element; the link itself or its parent li.
   * @returns {?Element}
   */
  getDropdownLink(target) {
    return target.nodeName === 'A' ? target : selectChild(target, 'a');
  }

  /**
   * Remove a dropdown's opened state.
   *
   * @param {Element} target - The dropdown link or its parent li.
   */
  removeOpenedState(target) {
    this.getDropdownLink(target).setAttribute('aria-expanded', 'false');
  }

  /**
   * Remove opened state from all dropdowns.
   *
   * @param {...Element} exclude - Items to exclude, should be dropdown links
   *   or their parent li.
   */
  removeAllOpenedStates(...exclude) {
    const activeLinks = this.topLevelParentLinks.filter(
      (link) => link.getAttribute('aria-expanded') === 'true',
    );
    if (activeLinks.length) {
      const excludesLinks = exclude
        .filter(isTruthy)
        .map((item) => this.getDropdownLink(item));
      activeLinks
        .filter((link) => !excludesLinks.includes(link))
        .forEach((link) => {
          this.removeOpenedState(link);
        });
    }
  }

  /**
   * Check if a dropdown is in its opened state.
   *
   * @param {Element} target - The dropdown link or its parent li.
   * @returns {boolean}
   */
  hasOpenState(target) {
    return (
      this.getDropdownLink(target).getAttribute('aria-expanded') === 'true'
    );
  }

  /**
   * Toggle a dropdown's opened state.
   *
   * @param {Element} target - The dropdown link or its parent li.
   */
  toggleOpenState(target) {
    const link = this.getDropdownLink(target);
    link.setAttribute(
      'aria-expanded',
      String(link.getAttribute('aria-expanded') === 'false'),
    );
  }

  /**
   * Set focus to the specified element if currently focused on something
   * outside the main nav.
   *
   * @param {Element} rootNode - Node to check if outside of.
   * @param {Element} focusNode - Node to set focus to if relevant.
   */
  setFocusIfOutside = (rootNode, focusNode) => {
    setTimeout(() => {
      if (!rootNode.contains(document.activeElement)) {
        focusNode.focus();
      }
    });
  };

  /**
   * Check toggled menu state on resize.
   */
  handleResize = () => {
    this.checkMenuToggleState();
    this.topLevelParentLinks.forEach((a) => {
      this.setDropdownLinkAttr(a);
    });
    this.setToggleMenuHeight();
  };

  /**
   * Remove opened state when leaving the last link in a dropdown.
   *
   * @param {object} e - Blur event.
   */
  handleLastDropdownLinkBlur = (e) => {
    if (!this.isToggledMenu) {
      const topLevelItem = e.currentTarget.closest(
        `[data-${TOP_LEVEL_PARENT_DATA_ATTR}]`,
      );
      // Don't close if tabbing from the last item back to a previous item
      if (e.relatedTarget && topLevelItem.contains(e.relatedTarget)) {
        return;
      }
      this.removeOpenedState(topLevelItem);
    }
  };

  /**
   * Prevent dropdown parent links from opening unless it's in a new tab, or
   * if hovering with a mouse.
   *
   * @param {object} e - Click event.
   */
  handleButtonizedLinkClick = (e) => {
    if (!this.isToggledMenu && !modifierKeyWatcher.isHoldingNewTabKey()) {
      const li = e.currentTarget.parentNode;
      if (getData(li, MOUSE_STATUS_DATA_ATTR)) {
        // Hovering with a mouse will reveal the dropdown without it having an
        // opened state, let the click pass through if the sub menu is fully
        // opaque.
        const subMenu = selectChild(li, '.sub-menu');
        const subMenuStyle = window.getComputedStyle(subMenu);
        if (parseFloat(subMenuStyle.opacity) === 1) {
          return;
        }
      }

      e.preventDefault();
      this.toggleOpenState(e.currentTarget);
    }
  };

  /**
   * Prevent dropdown parent links from opening unless it's in a new tab.
   *
   * @param {object} e - Keydown event.
   */
  handleButtonizedLinkKeydown = (e) => {
    // Ctrl/Cmd/Alt + Enter is for opening in a new tab just like for click.
    if (
      isButtonActivationKey(e.key) &&
      !modifierKeyWatcher.isHoldingNewTabKey()
    ) {
      e.preventDefault();
      // Trigger any click handlers
      e.currentTarget.click();
    }
  };

  /**
   * Close the toggled menu when clicking on the 'background overlay', which is
   * actually just the menu background.
   *
   * @param {object} e - Click event.
   */
  handleMainNavClick = (e) => {
    if (
      this.isToggledMenu &&
      // Not clicking on something inside the list
      !this.menuList.contains(e.target)
    ) {
      this.menuToggle.click();
    }
  };

  /**
   * Close the toggled menu when pressing escape.
   *
   * @param {object} e - Keydown event.
   */
  handleMainNavKeydown = (e) => {
    if (this.isToggledMenu && e.key === 'Escape') {
      this.menuToggle.click();
      this.menuToggle.focus();
    }
  };

  /**
   * Focus the last link if leaving the menu toggle.
   */
  handleMenuToggleBlur = () => {
    if (this.isToggledMenu) {
      this.setFocusIfOutside(this.mainNav, this.lastMainNavLink);
    }
  };

  /**
   * Focus the menu toggle if leaving the last link.
   */
  handleMainNavLastBlur = () => {
    if (this.isToggledMenu) {
      this.setFocusIfOutside(this.mainNav, this.menuToggle);
    }
  };

  /**
   * Set mouse data attribute for `handleButtonizedLinkClick()`.
   *
   * @param {object} e - Mouseenter event.
   */
  handleDropdownParentMouseEnter = (e) => {
    setData(e.currentTarget, MOUSE_STATUS_DATA_ATTR, true);
  };

  /**
   * Set mouse data attribute for `handleButtonizedLinkClick()`.
   *
   * @param {object} e - Mouseleave event.
   */
  handleDropdownParentMouseLeave = (e) => {
    setData(e.currentTarget, MOUSE_STATUS_DATA_ATTR, false);
  };

  /**
   * Focus the search input when opening its dialog.
   *
   * @param {object} e - Toggle event.
   * @param {boolean} isOpen - If the toggle is open.
   */
  handleSearchToggle = (e, isOpen) => {
    if (isOpen) {
      this.searchInput.focus();
    }
  };

  /**
   * Close the search dialog when pressing escape.
   *
   * @param {object} e - Keydown event.
   */
  handleSearchInputKeydown = (e) => {
    if (e.key === 'Escape') {
      this.searchToggle.click();
      // Try to let the button go back to its collapsed state before setting
      // focus back on it.
      setTimeout(() => {
        this.searchToggle.focus();
      });
    }
  };

  /**
   * Close open dropdowns when clicking outside them.
   *
   * @param {object} e - Keydown event.
   */
  handleBodyClick = (e) => {
    if (!this.isToggledMenu) {
      const parentItem = e.target.closest(
        `[data-${TOP_LEVEL_PARENT_DATA_ATTR}]`,
      );
      // Exclude any current one
      this.removeAllOpenedStates(parentItem);
    }
  };
}

import {
  initializer,
  FORM_CONTROLLER_ID,
  LIGHTBOX_CONTROLLER_ID,
  FocusClass,
  PageUpdater,
  HashLinkFix,
  JSAttr,
  Tabs,
  Toggle,
  ViewportHeight,
} from 'js-common';

// Controllers
import Menu from './Menu';
import FormListener from './FormListener';

import {
  CARD_SLIDER_CONTROLLER_ID,
  LOAN_CALCULATOR_CONTROLLER_ID,
  MEMBER_REGISTRATION_CONTROLLER_ID,
  INTRO_SLIDER_CONTROLLER_ID,
  INTRO_VIDEO_CONTROLLER_ID,
} from './constants';

initializer
  .register([
    HashLinkFix,
    JSAttr,
    Toggle,
    Menu,
    PageUpdater,
    Tabs,
    FocusClass,
    ViewportHeight,
    FormListener,
    [
      FORM_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'Form' */ 'js-common/Form'),
    ],
    [
      MEMBER_REGISTRATION_CONTROLLER_ID,
      () =>
        import(
          /* webpackChunkName: 'MemberRegistration' */ './MemberRegistration'
        ),
    ],
    [
      LOAN_CALCULATOR_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'LoanCalculator' */ './LoanCalculator'),
    ],
    [
      CARD_SLIDER_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'CardSlider' */ './CardSlider'),
    ],
    [
      INTRO_SLIDER_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'IntroSlider' */ './IntroSlider'),
    ],
    [
      INTRO_VIDEO_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'IntroVideo' */ './IntroVideo'),
    ],
    [
      LIGHTBOX_CONTROLLER_ID,
      () => import(/* webpackChunkName: 'Lightbox' */ 'js-common/Lightbox'),
    ],
  ])
  .init();

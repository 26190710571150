import { ElementController, getData } from 'js-common';

/**
 * Controller for adding data to the data layer used by Google Tag Manager at
 * form submits.
 *
 * @extends ElementController
 */
export default class FormListener extends ElementController {
  static id = 'FormListener';

  /**
   * DOM loaded, bind events.
   */
  init() {
    this.formId = this.root.id;
    this.formAction = this.root.action;
    this.formName = getData(this.root, 'form-name');

    this.on(this.root, 'submit', this.handleSubmit);
  }

  handleSubmit = () => {
    window.dataLayer.push({
      event: 'form_submit',
      form_id: this.formId,
      form_name: this.formName,
      form_destination: this.formAction,
    });
  };
}

import getDaysInMonth from 'date-fns/getDaysInMonth';
import getDaysInYear from 'date-fns/getDaysInYear';
import clamp from 'lodash/clamp';
import floor from 'lodash/floor';

export const SAVING_SYSTEM_SAVING = 'save-requirement';
export const SAVING_SYSTEM_RELIEF = 'save-relief';

/**
 * Check if a key is used to trigger buttons.
 *
 * @param {string} key - Keyboard key name (event.key).
 * @returns {boolean}
 */
export function isButtonActivationKey(key) {
  return key === 'Enter' || key === ' ';
}

/**
 * The (possibly prefixed) transitionend event name.
 *
 * @type {?string}
 */
export const TRANSITION_END_EVENT_NAME = (function getTransitionEndEventName() {
  const el = document.createElement('div');
  const transitions = {
    transition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd otransitionend',
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const t in transitions) {
    if (Object.prototype.hasOwnProperty.call(transitions, t) && el.style[t] !== undefined) {
        return transitions[t];
      }
  }

  return null;
})();

/* -Loan calculation
---------------------------------------------------------------------------- */
const NOW = new Date();

// A proper solution would be to read the number of decimals and multiply with
// as much as possible while staying below MAX_SAFE_INTEGER. This should be
// fine for now though.
const FLOAT_POW = 10000;

/**
 * Add fractional numbers while avoiding floating point rounding errors.
 *
 * @param {...number} nums - Numbers to add.
 * @returns {number}
 * @example
 *
 * addFloats(10.3, 1.3, 2.6);
 * // => 14.2
 */
export function addFloats(...nums) {
  return nums.reduce((sum, num) => sum + num * FLOAT_POW, 0) / FLOAT_POW;
}

/**
 * Subtract fractional numbers while avoiding floating point rounding errors.
 *
 * @param {...number} nums - Numbers to subtract. The first one is the base and
 *   each subsequent one is subtracted from it.
 * @returns {number}
 * @example
 *
 * subtractFloats(10.3, 1.5, 2.3);
 * // => 6.5
 */
export function subtractFloats(...nums) {
  return (
    nums
      .slice(1)
      .reduce((sum, num) => sum - num * FLOAT_POW, nums[0] * FLOAT_POW) /
    FLOAT_POW
  );
}

/**
 * Get the percentage of a value between the range of two values.
 *
 * @param {number} val - Value to check.
 * @param {number} min - Lower bound of range.
 * @param {number} max - Upper bound of range.
 * @returns {number} Between 0 and 100.
 * @example
 *
 * // 10 is at 50% between 0 and 20
 * getPercentageInRange(10, 0, 20);
 * // => 50
 */
export function getPercentageInRange(val, min, max) {
  return ((val - min) * 100) / (max - min);
}

/**
 * Get the maximum number for the save points input.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amorization years.
 * @returns {number}
 */
export function getMaxSavePoints(amount, years) {
  return Math.ceil((amount * (years * 12 + 1)) / 2);
}

/**
 * Get the maximum number for the saving input (a percentage of the monthly
 * amortization amount, usually 0–50%).
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amorization years.
 * @param {number} minPercent - The minimum percentage of the amortization
 *   amount, between 0 and 100+.
 * @returns {number}
 */
export function getMinSaving(amount, years, minPercent) {
  return Math.ceil((amount / (years * 12)) * (minPercent / 100));
}

/**
 * Get the maximum number for the saving input (a percentage of the monthly
 * amortization amount, usually 100–200%).
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amorization years.
 * @param {number} maxPercent - The maximum percentage of the amortization
 *   amount, between 0 and 100+.
 * @returns {number}
 */
export function getMaxSaving(amount, years, maxPercent) {
  return Math.ceil((amount / (years * 12)) * (maxPercent / 100));
}

/**
 * Get the monthly interest cost of a loan.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} interestRate - Loan interest rate percentage between
 *   0 and 100.
 * @returns {number}
 */
export function getMonthlyInterest(amount, interestRate) {
  return (
    amount * (interestRate / 100) * (getDaysInMonth(NOW) / getDaysInYear(NOW))
  );
}

/**
 * Get the total interest cost of a loan.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} interestRate - Loan interest rate percentage between
 *   0 and 100.
 * @returns {number}
 */
export function getTotalInterest(amount, years, interestRate) {
  return Math.ceil(
    (years * 12 + 1) * ((amount * (interestRate / 100 / 12)) / 2),
  );
}

/**
 * Get the cost for a selected saving system.
 *
 * @param {string} savingSystem - The saving system to calculate for.
 * @param {number} costBase
 * @param {number} amount - The amount.
 * @param baseAmount - An optional base amount to be added to the saving system cost.
 * @returns {number}
 */
export function getSavingSystemCost(savingSystem, costBase, amount, baseAmount=0) {
  const savingChoiceSaveRequirementCost = Math.ceil(amount * costBase);
  const savingChoiceReliefRequirementCost = Math.ceil(
    savingChoiceSaveRequirementCost * 0.1,
  );
  if (savingSystem === SAVING_SYSTEM_SAVING) {
    return savingChoiceSaveRequirementCost + baseAmount;
  }
  if (savingSystem === SAVING_SYSTEM_RELIEF) {
    return savingChoiceReliefRequirementCost + baseAmount;
  }
  return 0 + baseAmount;
}

/**
 * Get the total saving requirement of a loan.
 *
 * @param {number} monthlySavings - Total saving amount for one month.
 * @param {number} years - Number of amortization years.
 * @returns {number}
 */
export function getTotalSaving(monthlySavings, years) {
  return Math.ceil(years * 12 * monthlySavings);
}

/**
 * Get the total save points required for a loan.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param percent - The percent that should be calculated
 * @returns {number}
 */
export function calculateSavePoints(amount, years, percent) {
  const savePoints = amount * percent * (years * 12);
  if (savePoints >= 1000000) {
    return Math.round(savePoints / 100000) * 100000;
  }
  return Math.round(savePoints / 1000) * 1000;
}

/**
 * Get the monthly cost for a JAK loan.
 *
 * Has a monthly cost of amortization + interest + required amount of saving.
 * The required amount of saving matches the amortization but can be reduced
 * by adding points obtained from existing saving.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} savePoints - Added save points.
 * @param {number} maxSavePoints - Max allowed save points.
 * @param {number} effectiveInterestRate - Loan interest rate percentage,
 *   between 0 and 100.
 * @returns {object}
 */
export function getMonthlyCostJak(
  amount,
  years,
  savePoints,
  maxSavePoints,
  effectiveInterestRate,
) {
  const months = years * 12;
  const amortization = amount / months;
  const savingReduction = (savePoints / maxSavePoints) * amortization;
  const saving = amortization - savingReduction;
  const interest = getMonthlyInterest(amount, effectiveInterestRate);

  return {
    amortization: Math.ceil(amortization),
    saving: Math.ceil(saving),
    interest: Math.ceil(interest),
    total: Math.ceil(amortization) + Math.ceil(saving) + Math.ceil(interest),
  };
}

/**
 * Get the monthly cost for a new JAK loan.
 *
 * Has a monthly cost of amortization + interest + required amount of saving.
 * The required amount of saving matches the amortization but can be reduced
 * by adding points obtained from existing saving.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} effectiveInterestRate - Loan interest rate percentage,
 *   between 0 and 100.
 * @returns {object}
 */
export function getMonthlyCostJakNew(amount, years, effectiveInterestRate) {
  const months = years * 12;
  const amortization = amount / months;
  const saving = 0;
  const interest = getMonthlyInterest(amount, effectiveInterestRate);

  return {
    amortization: Math.ceil(amortization),
    saving: Math.ceil(saving),
    interest: Math.ceil(interest),
    total: Math.ceil(amortization) + Math.ceil(saving) + Math.ceil(interest),
  };
}

/**
 * Get the monthly cost for an environment loan.
 *
 * Has a monthly cost of amortization + interest, without any other affecting
 * parameters like the other loan types.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} effectiveInterestRate - Loan interest rate percentage,
 *   between 0 and 100.
 * @returns {number}
 */
export function getMonthlyCostEnvironment(
  amount,
  years,
  effectiveInterestRate,
) {
  const months = years * 12;
  const amortization = amount / months;
  const interest = getMonthlyInterest(amount, effectiveInterestRate);

  return {
    amortization: Math.ceil(amortization),
    interest: Math.ceil(interest),
    total: Math.ceil(amortization) + Math.ceil(interest),
  };
}

/**
 * Get the monthly cost for a flexible loan.
 *
 * Has a monthly cost of amortization + interest, where the interest can be
 * reduced by saving. The interest discount ranges from 0 percentage points
 * (with no saving) to the difference in percentage points between min and max
 * interest rate (when saving twice the amortization amount).
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} saving - Amount of saving per month, affects the final
 *   interest rate.
 * @param {number} minInterestRate - Minimum loan interest rate percentage,
 *   between 0 and 100.
 * @param {number} maxInterestRate - Maximum loan interest rate percentage,
 *   between 0 and 100.
 * @param {number} interestRateAddition - Percentage points to add to the
 *   interest rate, between 0 and 100.
 * @param {number} minSavingPercent - Minimum saving percentage (of amortization
 *   amount) between 0 and 100+ (usually 0–50).
 * @param {number} maxSavingPercent - Maximum saving percentage (of amortization
 *   amount) between 0 and 100+ (usually 100–200).
 * @returns {number}
 */
export function getMonthlyCostFlexi(
  amount,
  years,
  saving,
  minInterestRate,
  maxInterestRate,
  interestRateAddition,
  minSavingPercent,
  maxSavingPercent,
) {
  const months = years * 12;
  const amortization = amount / months;
  const savingPercentage = getPercentageInRange(
    saving,
    amortization * (minSavingPercent / 100),
    amortization * (maxSavingPercent / 100),
  );
  // Percentage points
  const interestRateDiscount = floor(
    (maxInterestRate - minInterestRate) * (savingPercentage / 100),
    1,
  );
  const interestRate = clamp(
    subtractFloats(maxInterestRate, interestRateDiscount),
    minInterestRate,
    maxInterestRate,
  );
  const effectiveInterestRate = addFloats(interestRate, interestRateAddition);
  const interest = getMonthlyInterest(amount, effectiveInterestRate);

  return {
    amortization: Math.ceil(amortization),
    saving: Math.ceil(saving),
    interest: Math.ceil(interest),
    total: Math.ceil(amortization) + Math.ceil(saving) + Math.ceil(interest),
    interestRate,
    interestRateDiscount,
    effectiveInterestRate,
  };
}

/**
 * Get the total interest cost of a loan.
 *
 * @param {number} amount - Total loan amount.
 * @param {number} years - Number of amortization years.
 * @param {number} amortization - Monthly amortization amount.
 * @param {number} interestRate - Loan interest rate percentage between
 *   0 and 100.
 * @returns {number}
 */
export function getTotalInterestOld(amount, years, amortization, interestRate) {
  // Function adapted from the old/existing calculator, not sure about the
  // formulas used.
  const currentYear = NOW.getFullYear();
  let amountLeft = amount;
  let monthlyAmortization = amortization;
  let totalAmortization = 0;
  let totalInterest = 0;
  let isLastRun = false;
  for (let year = currentYear; year <= currentYear + years; year += 1) {
    if (isLastRun) {
      break;
    }
    const daysInYear = getDaysInYear(new Date(year));
    // Months are zero-indexed in JS
    for (let month = 0; month <= 11; month += 1) {
      if (isLastRun) {
        break;
      }
      const daysInMonth = getDaysInMonth(new Date(year, month));
      totalAmortization += monthlyAmortization;
      totalInterest += Math.round(
        ((amountLeft * (interestRate / 100)) / daysInYear) * daysInMonth,
      );
      amountLeft -= monthlyAmortization;
      if (amountLeft <= monthlyAmortization && amountLeft > 0) {
        monthlyAmortization = amount - totalAmortization;
        isLastRun = true;
      }
    }
  }
  return totalInterest;
}
